import logo from './logo.svg';
import './App.css';
import Landing from './landing.js';

function App() {
  return (
    <div>
	<Landing/>
    </div>
  );
}

export default App;
